import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

const welcomeVariant = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.2,
			type: 'tween',
		},
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.2,
			type: 'tween',
		},
	},
}

const Welcome: React.FC = () => {
	const [connectWallet, setConnectWallet] = useState<boolean>(false)
	const { onOpen } = useConnectWalletContext()

	return (
		<>
			{!connectWallet ? (
				<motion.div
					variants={welcomeVariant}
					initial="hidden"
					animate="visible"
					exit="hidden"
					className="w-full h-[100vh] welcomeImage"
				>
					<div className="w-full h-full flex flex-col justify-between items-center py-16 2xl:py-24 relative">
						<div className="w-full absolute top-0 h-1/3 md:hidden">
							<Image src="/images/welcome/curved-head.svg" layout="fill" objectFit="cover" />
						</div>
						<div className="logoImage mt-8 2xl:mt-20"></div>
						<div className="w-full flex flex-col justify-around items-center py-2" style={{ minHeight: '40%' }}>
							<h1 className="uppercase text-whiteText font-normal text-center tracking-tight kaftan flex flex-col text-4xl leading-10 mb-4 md:text-[5.5rem] md:leading-[6rem]">
								Communities <span>with Superpowers</span>
							</h1>
							<p className="text-blackText text-sm md:text-xl font-semibold text-center w-2/3 md:w-full">
								Powerful Tools For Creators To Build & Operate Tokenized Communities
							</p>
							<div className="w-4/5 md:w-2/3 xl:w-[30%] flex flex-row justify-around items-center mt-10">
								<Link href="/dashboard" passHref>
									<button className="bg-black px-4 md:px-12 py-3 md:py-3 text-whiteText font-semibold rounded-full uppercase   text-xs md:text-sm">
										Explore
									</button>
								</Link>

								<button
									className="bg-black px-4 md:px-11 py-3 md:py-3 text-whiteText font-semibold rounded-full uppercase   text-xs md:text-sm"
									onClick={() => setConnectWallet(true)}
								>
									Create Token
								</button>
							</div>
						</div>

						<div className="flex flex-col justify-evenly items-center w-2/3 md:w-1/2 mt-6">
							<h2 className="uppercase text-sm md:text-base tracking-moreWide text-whiteText mb-4 leading-[23px]">
								Our creators
							</h2>
							<div className="flex justify-around items-center flex-wrap w-full">
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">Forefront</h2>
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">GCR</h2>
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">The Culture DAO</h2>
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">Jump</h2>
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">Ampled</h2>
								<h2 className="text-whiteText text-base md:text-2xl kaftan px-2 md:px-0 leading-8">Squiggle DAO</h2>
							</div>
						</div>
					</div>
				</motion.div>
			) : (
				<motion.div
					variants={welcomeVariant}
					initial="hidden"
					animate="visible"
					exit="hidden"
					className="w-full min-h-screen bg-[#1A1919] fixed z-0"
				>
					<div className="min-w-min flex flex-col justify-around items-center gap-y-8 mt-20">
						<div className="logoImage mt-20 md:mt-0"></div>
						<Image src="/images/welcome/portal.svg" height={270} width={270} quality={100} />
						<h1 className="text-whiteText  font-medium text-sm z-10   ">Connect your wallet to continue</h1>
						<button
							className="h-10 text-sm bg-gradient1 uppercase   text-whiteText dark:text-blackText px-8 py-0 font-semibold rounded-full grid place-items-center focus:outline-none z-10"
							onClick={onOpen}
						>
							Connect Wallet
						</button>
					</div>
				</motion.div>
			)}
		</>
	)
}

export default Welcome
