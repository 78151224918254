import { NextSeo } from 'next-seo'
import { FC } from 'react'

type Props = {
	noBanner?: boolean
	title?: string
	description?: string
	image?: string
}

const SEO: FC<Props> = ({ children, title, description, image }) => {
	return (
		<>
			<NextSeo
				title={title}
				defaultTitle="Coinvise"
				titleTemplate="%s | Coinvise"
				description={description ?? 'Powerful Tools For Creators To Build & Operate Web3 Communities'}
				openGraph={{
					url: 'https://www.coinvise.co/',
					site_name: 'Coinvise',
					description: description ?? 'Powerful Tools For Creators To Build & Operate Web3 Communities',
					title: title ? `${title} | Coinvise` : 'Coinvise',
					images: [
						image
							? {
									url: image,
									type: 'image/png',
									width: 1200,
									height: 628,
							  }
							: {
									url: 'https://res.cloudinary.com/coinvise/image/upload/v1652877403/coinvise.co/metaimages/HOMEPAGE_log7b0.png',
									width: 1200,
									height: 628,
									alt: 'Coinvise',
									type: 'image/png',
							  },
					],
				}}
				twitter={{
					handle: '@CoinviseCo',
					site: '@CoinviseCo',
					cardType: 'summary_large_image',
				}}
			/>
			{children}
		</>
	)
}

export default SEO
