import { motion } from 'framer-motion'
import { FC } from 'react'

const SplashScreen: FC = () => {
	return (
		<div className="w-full h-[100vh] dark:bg-dark-1 grid place-items-center">
			<motion.svg width="49" height="51" viewBox="0 0 49 51" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.79253 9.49221C13.7227 5.5633 19.0524 3.35468 24.6106 3.35157C30.1169 3.39778 35.3841 5.60699 39.2749 9.50218C43.1663 13.3979 45.3683 18.6672 45.4059 24.1719V47.3516H3.65039V24.316C3.65039 18.7561 5.85965 13.4239 9.79253 9.49221Z"
					stroke="url(#loader)"
					strokeWidth="6"
				/>
				<defs>
					<motion.linearGradient
						id="loader"
						x1="40.0505"
						y1="1.77941"
						x2="0.527197"
						y2="50.2511"
						gradientUnits="userSpaceOnUse"
					>
						<motion.stop
							animate={{ stopColor: ['#EF764B', '#F7B24C'], stopOpacity: [0, 1] }}
							transition={{
								repeat: Infinity,
								repeatType: 'mirror',
								ease: 'easeInOut',
								duration: 0.5,
							}}
						/>
						<motion.stop
							offset="0.703125"
							animate={{ stopColor: '#F97546', offset: [0.7, 0.2] }}
							transition={{
								repeat: Infinity,
								repeatType: 'mirror',
								ease: 'easeInOut',
								duration: 0.5,
							}}
						/>
						<motion.stop
							offset="1"
							animate={{ stopColor: ['#F7B24C', '#EF764B'], stopOpacity: [1, 0] }}
							transition={{
								repeat: Infinity,
								repeatType: 'mirror',
								ease: 'easeInOut',
								duration: 0.5,
							}}
						/>
					</motion.linearGradient>
				</defs>
			</motion.svg>
		</div>
	)
}

export default SplashScreen
